import React, { useState } from "react";
import { Button, Form, Input,Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {loginSuccess} from "../../redux/slices/authSlice";
import { useUserLoginMutation } from "../../redux/slices/userSlice";
import Swal from 'sweetalert2'

const Login = () => {
    const navigate = useNavigate();
    const dispatch=useDispatch();
    const [form] = Form.useForm();

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [userLogin, { isLoading }] = useUserLoginMutation();

    const onFinish = async(values) => {
        const response=await userLogin(values);

        form.resetFields();

        if(response.error){
            Swal.fire({
                title: response.error.data.message,
                icon: "error",
                confirmButtonText: 'OK'
            });
            return;
        }
        
        const { token,message }=response.data;

        if(token){
            dispatch(loginSuccess({ user: response.data.user }));
            Swal.fire({
                title: message,
                icon: "success"
            });
            navigate("/");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleFormChange = () => {
        const values = form.getFieldsValue();
        const allFieldsFilled = values.email && values.password;
        setIsButtonDisabled(!allFieldsFilled);
    };

    return (
        <div className="authWrapper">
            <div className="innerWrapper">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-8">
                            <h4 className="cardTitle">Lifeline Inventory</h4>
                            <div className="card authCard">
                                <div className="card-body">
                                    <h5 className="title">Log In</h5>
                                    <div className="formWrapper">
                                        {isLoading ? (
                                            <div className="loadingWrapper">
                                                <Spin size="large" />
                                            </div>
                                        ) : (
                                            <Form
                                                form={form}
                                                name="loginForm"
                                                onFinish={onFinish}
                                                onFinishFailed={onFinishFailed}
                                                onValuesChange={handleFormChange}
                                                autoComplete="off"
                                                layout="vertical"
                                            >
                                                <Form.Item
                                                    label="Email"
                                                    name="email"
                                                    rules={[
                                                        {
                                                            type: "email",
                                                            message: "The input is not valid E-mail!",
                                                        },
                                                        {
                                                            required: true,
                                                            message: "Please input your email!",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className="formControl"
                                                        placeholder="Enter your email"
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label="Password"
                                                    name="password"
                                                    className="mb-3"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please input your password!",
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password
                                                        className="formControl"
                                                        placeholder="Enter password here"
                                                    />
                                                </Form.Item>
                                                
                                                <Form.Item className="w-100 text-end">
                                                    <Link to={"/password-reset"} className="forgotLink text-capitalize">
                                                        Forgot Password?
                                                    </Link>
                                                </Form.Item>

                                                <Form.Item className="text-center mb-4 w-100">
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        className="authBtn w-100"
                                                        disabled={isButtonDisabled}
                                                    >
                                                        Log In
                                                    </Button>
                                                </Form.Item>

                                                
                                                <p className="orText text-capitalize">Don't have an account? <Link to={"/register"}>Sign Up</Link></p>
                                            </Form>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
