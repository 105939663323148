import React, { useState, useEffect } from "react";
import { Button, Form, Input, message, Upload } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useUserProfileMutation } from "../../redux/slices/userSlice";
import { userDetail } from "../../redux/slices/authSlice";
import Swal from "sweetalert2";
const AccountForm = ({ onImageChange }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [userProfile] = useUserProfileMutation();
    const userData = useSelector(
        (state) => state?.persistedReducer?.user?.user
    );
    useEffect(() => {
        // Set the form fields with the user's data as initial values
        
        const initialFormValues = {
            name: userData !== undefined ? userData.name : "",
            user_name: userData !== undefined ? userData.user_name : "",
            email: userData !== undefined ? userData.email : "",
        };

        form.setFieldsValue(initialFormValues);
        setInitialValues(initialFormValues);
        checkAllFieldsFilled(); // Check if all fields are filled initially
    }, [form, userData]);
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
        }
        return isJpgOrPng && isLt2M;
    };
    const handleImageChange = (info) => {
        if (info.fileList.length > 0) {
            if (info.file.status === "uploading") {
                setLoading(true);
                return;
            }
            if (info.file.status === "done") {
                getBase64(info.file.originFileObj, (url) => {
                    setLoading(false);
                    onImageChange(url);
                });
            }
        } else {
            alert("no image uploaded")
        }
    };
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: "none",
            }}
            type="button"
        >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Choose Photo
            </div>
        </button>
    );
    const onFinish = async (values) => {
        // Checking if user has done changes in the API or not 
        const hasChanges = Object.keys(values).some(
            (key) => values[key] !== initialValues[key]
        );

        if (!hasChanges) {
            Swal.fire({
                title: "No changes detected",
                text: "Please make some changes before updating the Profile.",
                icon: "info",
                confirmButtonText: "OK",
            });
            return; 
        }
        const formData = new FormData();
        // Append form fields to FormData
        formData.append('name', values.name);
        formData.append('user_name', values.user_name);
        formData.append('email', values.email);
        if (values.profile_image?.file?.originFileObj) {
            formData.append('profile_image', values.profile_image.file.originFileObj);
        }
        const response = await userProfile({ user: formData, id: userData !== undefined ? userData.id :"" })
        if (response.data) {
            dispatch(
                userDetail({
                    user: response?.data.user,
                })
            );
            Swal.fire({
                title: response?.data.message,
                icon: "success",
            });
        } else {
            Swal.fire({
                title: response.error.data.error,
                icon: "error",
                confirmButtonText: "OK",
            });
        }
        navigate("/");
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    const checkAllFieldsFilled = () => {
        const values = form.getFieldsValue();
        const allFieldsFilled = values.name && values.user_name && values.email
        setIsButtonDisabled(!allFieldsFilled);
    };
    const handleFormChange = () => {
        checkAllFieldsFilled();
    };
    return (
        <Form
            form={form}
            name="accountForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={handleFormChange}
            autoComplete="off"
            layout="vertical"
        >
            <Form.Item
                label="Full Name"
                name="name"
                rules={[
                    {
                        required: true,
                        message: "Please enter full name!",
                    },
                ]}
            >
                <Input
                    className="formControl"
                    placeholder="Enter your full name"
                    initialvalues={userData !== undefined ? userData.name :""}
                />
            </Form.Item>
            <Form.Item
                label="Username"
                name="user_name"
                rules={[
                    {
                        required: true,
                        message: "Please enter username!",
                    },
                ]}
            >
                <Input
                    className="formControl"
                    placeholder="Enter your username"
                    initialvalues={userData !== undefined ? userData.user_name :""}
                />
            </Form.Item>
            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        message: "Please input your email!",
                    },
                ]}
            >
                <Input
                    className="formControl"
                    placeholder="Enter your email"
                    disabled
                    initialvalues={userData !== undefined ? userData.email :""}
                />
            </Form.Item>
            <Form.Item label="Profile Image" name="profile_image" valuePropName="avatar">
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={handleImageChange}
                    customRequest={({ file, onSuccess }) => {
                        setTimeout(() => {
                            onSuccess("ok");
                        }, 0);
                    }}
                >
                    {uploadButton}
                </Upload>
            </Form.Item>
            <Form.Item className="text-center mb-4 w-100">
                <Button
                    type="primary"
                    htmlType="submit"
                    className="authBtn w-100"
                    disabled={isButtonDisabled}
                >
                    Update
                </Button>
            </Form.Item>
        </Form>
    );
};
export default AccountForm;