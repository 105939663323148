import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import { useGetProductMutation } from "../../redux/slices/productSlice";

const FilterProducts = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const { categoryId } = location.state || {};
  const [getProduct] = useGetProductMutation();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleNavigate = (productId) => {
    navigate("/product-details", {
      state: {productId: productId },
    });
  };
  useEffect(() => {
    getProduct()
      .then((res) => {
        const filtereddata = res.data.filter(
          (item) => item.category_id === categoryId
        );
        setFilteredData(filtereddata);
      })
      .catch((err) => {
        console.error("err", err);
      });
  }, []);

  return (
    <div className="wrapper">
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="titleBar d-flex justify-content-between">
              <h4>Products</h4>
              <div className="buttonWrap">
                <button
                  type="button"
                  className="btn backBtn"
                  onClick={() => handleGoBack()}
                >
                  <MdArrowBack /> Back
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="categoryWrapper filterProductWrapper">
              <div className="categoryList">
                {filteredData.map((item) => (
                  <div
                    className="categoryItems"
                    key={item.id}
                    onClick={() => handleNavigate(item.id)}
                  >
                    <div className="categoryImage">
                      <img
                        src={item.image}
                        alt="category"
                        className="img-fluid"
                      />
                    </div>
                    <div className="categoryName">
                      <h5>{item.item_name}</h5>
                      <span>{item.company_name}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterProducts;