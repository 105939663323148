import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Api_Url } from "../../Helper";
const commonHeaders = {
  Accept: "application/json",
};
export const productSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: Api_Url }),
  endpoints: (builder) => ({
    getProduct: builder.mutation({
      query: () => {
        return {
          url: "/products",
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),
    addProduct: builder.mutation({
      query: (productdata) => {
        return {
          url: "/products",
          method: "POST",
          body: productdata,
          headers: commonHeaders,
        };
      },
    }),
    updateProduct: builder.mutation({
      query: ({productdata,id}) => {
        return {
          url: `/products/${id}`,
          method: "PUT",
          body: productdata,
          headers: commonHeaders,
        };
      },
    }),
  }),
});
export const { useGetProductMutation, useAddProductMutation ,useUpdateProductMutation} = productSlice;
