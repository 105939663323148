import React,{useState,useEffect} from 'react';
import { Table, Input } from "antd";
import { useGetPatientMutation } from '../../redux/slices/patientSlice';

const Patient = () => {
 const [getPatient,{isLoading}] = useGetPatientMutation();
 const [patientData, setpatientData] = useState([]);
 const [filteredPatientData, setFilteredPatientData] = useState([]);
  useEffect(() => {
    getPatient()
      .then((res) => {
        const formattedData = res.data.map((item) => ({
          key: item.id,
          customerid:item.customer_id,
          name: `${item.first_name} ${item.last_name}`,
          email: item.email,
          phone:item.phone,
          city: item.city,
          state:item.state,
          zip:item.zip
        }));
        setpatientData(formattedData);
        setFilteredPatientData(formattedData);
      })
      .catch((err) => {
        console.error("err", err);
      });
  }, [getPatient]);
  const tableColumns = [
    {
      title: "Customer Id",
      dataIndex: "customerid",
      key: "customerid",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Zip",
      dataIndex: "zip",
      key: "zip",
    },
  ];

 //   For searching the elements in the SearchBar
 const handlePatientSearch = (event) => {
    const searchText = event.target.value.toLowerCase();

    const filteredData = patientData.filter((item) =>
      item.name.toLowerCase().includes(searchText)
    );
    setFilteredPatientData(filteredData);
  };



  return (
    <div className="wrapper">
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="titleBar d-flex justify-content-between">
              <h4>Patients Catalog</h4>
              <Input
                placeholder="Search by patient name"
                onChange={handlePatientSearch}
                className="homesearchInput"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="tableWrapper">
              <Table
                className="productTable"
                columns={tableColumns}
                dataSource={filteredPatientData}
                pagination={true}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Patient;