import React from 'react';
import { Route, Routes, Navigate  } from "react-router-dom";
import { useSelector } from 'react-redux';
import Login from '../pages/auth/login';
import ForgotPassword from '../pages/auth/forgotPassword';
import Account from '../pages/account';
import SidebarLayout from '../components/Layout/layout';
import Homepage from '../pages/home';
import Category from '../pages/category';
import Inventory from '../pages/inventory';
import Register from '../pages/auth/register';
import AddProducts from '../pages/products';
import FilterProducts from '../pages/category/filterProduct';
import ProductDetails from '../pages/category/productDetails';
import ResetPassword from '../pages/auth/resetPassword';
import EditProduct from '../pages/products/editProduct';
import Patient from '../pages/patient';
import Appointment from '../pages/appointments';

const AppRoute = () => {
    const userData = useSelector((state) => state.persistedReducer);
    const isAuthenticated = userData.isAuthenticated;
    
    return (
        <Routes>
            {isAuthenticated ? (
                <>
                    <Route element={<SidebarLayout />}>
                        <Route path="/" element={<Homepage />} />
                        <Route path="/category" element={<Category />} />
                        <Route path="/my-account" element={<Account />} />
                        <Route path="/inventory" element={<Inventory />} />
                        <Route path="/add-product" element={<AddProducts />} />
                        <Route path="/products" element={<FilterProducts />} />
                        <Route path="/product-details" element={<ProductDetails />} />
                        <Route path="/edit-product" element={<EditProduct />} />
                        <Route path="/patients" element={<Patient />} />    
                        <Route path="/appointments" element={<Appointment />} />    
                        <Route path="/login" element={<Navigate to="/" />} />
                        <Route path="/register" element={<Navigate to="/" />} />
                        <Route path="/password-reset" element={<Navigate to="/" />} />
                        <Route path="/forget-password"element={<Navigate to="/" />} />
                    </Route>
                </>
            ) : (
                <>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/password-reset" element={<ForgotPassword />} />
                    <Route path="/forget-password" element={<ResetPassword />} />
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="/category" element={<Navigate to="/login" />} />
                    <Route path="/my-account" element={<Navigate to="/login" />} />
                    <Route path="/products" element={<Navigate to="/login" />} />
                    <Route path="/product-details" element={<Navigate to="/login" />} />
                    <Route path="/inventory" element={<Navigate to="/login" />} />
                    <Route path="/add-product" element={<Navigate to="/login" />} />
                    <Route path="/edit-product" element={<Navigate to="/login" />} />
                    <Route path="/patients" element={<Navigate to="/login" />} />
                    <Route path="/appointments" element={<Navigate to="/login" />} />  
                </>
            )}
        </Routes>
    );
};

export default AppRoute;
