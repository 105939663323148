import React, { useState, useEffect } from "react";
import { Table, Select, Button, Input } from "antd";
import { MdRefresh } from "react-icons/md";
import {
  useGetProductMutation,
  useUpdateProductMutation,
} from "../../redux/slices/productSlice";

const Inventory = () => {
  const [loadings, setLoadings] = useState([]);
  const [getProduct,{isLoading}] = useGetProductMutation();
  const [updateProduct] = useUpdateProductMutation();
  const [productData, setproductData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [stockValues, setStockValues] = useState({});
  useEffect(() => {
    getProduct()
      .then((res) => {
        const formattedData = res.data.map((item) => ({
          key: item.id,
          imageUrl: item.image,
          company_name: item.company_name,
          name: item.item_name,
          category_id:item.category_id,
          stock: item.quantity,
        }));
        setproductData(formattedData);
        setFilteredData(formattedData);
        const initialStockValues = {};
        formattedData.forEach((item) => {
          initialStockValues[item.key] = item.stock;
        });
        setStockValues(initialStockValues);
      })
      .catch((err) => {
        console.error("err", err);
      });
  }, [getProduct]);
  const handleStockChange = (e, key) => {
    setStockValues({
      ...stockValues,
      [key]: e.target.value,
    });
  };
  const enterLoading = async (value, index) => {
    let data = {
      item_name: value.name,
      company_name: value.company_name,
      quantity: Number(stockValues[value.key]),
    };
    await updateProduct({ productdata: data, id: value.key });
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 3000);
  };

  const tableColumns = [
    {
      dataIndex: "companyImg",
      key: "companyImg",
      render: (text, record) => (
        <div className="imgBox">
          <img src={record.imageUrl} alt="Product" className="img-fluid" />
        </div>
      ),
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "In Stock",
      dataIndex: "stock",
      key: "stock",
      render: (text, record) => (
        <Input
          type="number"
          value={stockValues[record.key]}
          onChange={(e) => handleStockChange(e, record.key)}
          className="formControl"
          style={{ width: "150px" }}
        />
      ),
    },
    {
      title: "Update Stock",
      dataIndex: "update",
      key: "update",
      render: (text, record, index) => {
        return (
          <Button
            type="primary"
            icon={<MdRefresh />}
            loading={loadings[index]}
            onClick={() => enterLoading(record, index)}
            className="updateBtn"
          />
        );
      },
    },
  ];
  const handleCategoryChange=(value)=>{
    const filtereddata = productData.filter((item) => item.category_id === value);
    setFilteredData(filtereddata)
   }
  return (
    <div className="wrapper">
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="titleBar d-flex justify-content-between">
              <h4>Inventory Management</h4>
              <Select className="formSelect" placeholder="Choose Category" onChange={handleCategoryChange}>
                <Select.Option value={1}>Injectable</Select.Option>
                <Select.Option value={2}>Supplies</Select.Option>
                <Select.Option value={3}>Medications</Select.Option>
                <Select.Option value={4}>Skin Care</Select.Option>
              </Select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="tableWrapper">
              <Table
                className="productTable"
                columns={tableColumns}
                dataSource={filteredData}
                pagination={false}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inventory;