import React, { useState, useEffect } from "react";
import AccountForm from "./accountForm";
import { logout, userDeleteSuccess } from "../../redux/slices/authSlice";
import { useUserDeleteMutation } from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

const Account = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState("");
  const [userDelete] = useUserDeleteMutation();
  const handleImageChange = (url) => {
    setImageUrl(url);
  };
  const userData = useSelector((state) => state?.persistedReducer?.user?.user);
  useEffect(() => {
    let finalImageUrl =
      "https://proton-resources-production.imgix.net/1d975e89449cced100a300bd03e73f747465f4b6aa48fcf86657c0f832d06510.png?w=156&dpr=2&auto=format%2Ccompress&orient=";

    if (userData !== undefined && userData.profile_image) {
      finalImageUrl = userData.profile_image;
    }

    setImageUrl(finalImageUrl);
  }, [userData]);
  const handlelogout = () => {
    dispatch(logout());
    navigate("/login");
  };
  const handleDeleteaccount = async () => {
    Swal.fire({
      title: "Are You Sure Do you want to Delete this Account?",
      showDenyButton: true,
      icon: "warning",
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        const response = userDelete(userData.id);
        if (response) {
          dispatch(userDeleteSuccess());
          Swal.fire({
            title: "Your Account is Deleted Successfully ",
            icon: "success",
          });
          navigate("/login");
        }else if(response.error){
            Swal.fire({
                title: response.error.data.message,
                icon: "error",
                confirmButtonText: 'OK'
            });
            return;
        }
      } else if (result.isDenied) {
        Swal.fire({
          title: "Your Account is safe!",
          icon: "info",
          confirmButtonText: "OK",
        });
      }
    });
  };
  return (
    <div className="wrapper">
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="titleBar">
              <h4>My Account</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9">
            <div className="dltAccountWrapper">
              <div className="card accountCard">
                <div className="card-body">
                  <div className="innerBody">
                    <div className="profileImageSec">
                      <div className="imgWrapper">
                        <img
                          src={imageUrl}
                          className="img-fluid"
                          alt="profile"
                        />
                      </div>
                    </div>
                    <div className="formWrapper">
                      <AccountForm onImageChange={handleImageChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="dltAccountWrapper">
              <div className="card dltCard">
                <div className="card-body">
                  <h4 className="cardTitle">Time to go?</h4>
                  <button
                    type="button"
                    className="btn signOutBtn my-4"
                    onClick={handlelogout}
                  >
                    Sign Out
                  </button>
                  <button
                    type="button"
                    className="btn deleteAcBtn"
                    onClick={handleDeleteaccount}
                  >
                    Delete Account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
