import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Api_Url } from "../../Helper";
const commonHeaders = {
    Accept: "application/json",
  };
  export const patientSlice = createApi({
    reducerPath: "patientSlice",
    baseQuery: fetchBaseQuery({ baseUrl: Api_Url }),
    endpoints: (builder) => ({
      getPatient: builder.mutation({
        query: () => {
          return {
            url: "/patients",
            method: "GET",
            headers: commonHeaders,
          };
        },
      }),
      getAppointment: builder.mutation({
        query: () => {
          return {
            url: "/appointments",
            method: "GET",
            headers: commonHeaders,
          };
        },
      }),
     
    }),
  });
  export const { useGetPatientMutation,useGetAppointmentMutation} = patientSlice;