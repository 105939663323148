import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MdEdit, MdArrowBack, MdRefresh } from "react-icons/md";
import { Button, Input } from "antd";
import {
  useGetProductMutation,
  useUpdateProductMutation,
} from "../../redux/slices/productSlice";

const ProductDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loadings, setLoadings] = useState([]);
  const [inputText, setinputText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [getProduct] = useGetProductMutation();
  const [updateProduct] = useUpdateProductMutation();
  const { productId } = location.state || {};

  const enterLoading = async (index, item) => {
    let data = {
      item_name: item.item_name,
      company_name: item.company_name,
      quantity: inputText,
    };
    const response = await updateProduct({ productdata: data, id: productId });
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 3000);
  };

  const handleGoHome = () => {
    navigate("/edit-product",{
      state: {productId: productId },
    });
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    getProduct()
      .then((res) => {
        const filtereddata = res.data.filter((item) => item.id === productId);
        setFilteredData(filtereddata);
      })
      .catch((err) => {
        console.error("err", err);
      });
  }, []);

  return (
    <div className="wrapper">
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="titleBar d-flex justify-content-end">
              <div className="buttonWrap">
                <button
                  type="button"
                  className="btn backBtn"
                  onClick={() => handleGoBack()}
                >
                  <MdArrowBack /> Back
                </button>
                <button
                  type="button"
                  className="btn homeBtn"
                  onClick={() => handleGoHome()}
                >
                  <MdEdit /> Edit Product
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="detailsWrapper">
              {filteredData.map((item) => (
                <div className="row">
                  <div className="col-md-5">
                    <div className="productImageWrap">
                      <img
                        src={item.image}
                        className="img-fluid"
                        alt="product"
                      />
                    </div>
                  </div>

                  <div className="col-md-7 py-2">
                    <div className="productNameWrap mb-4">
                      <h3>{item.item_name}</h3>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="detailBox">
                          <h5>In Stock</h5>
                          <Input
                            type="number"
                            defaultValue={item.quantity}
                            onChange={(e) => {
                              setinputText(e.target.value);
                            }}
                            className="text-center"
                            style={{ width: "150px" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="detailBox">
                          <h5>Price</h5>
                          <p>{item.price}</p>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="detailBox">
                          <h5>SKU</h5>
                          <p>{item.sku}</p>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4 d-flex justify-content-center align-items-center">
                        <Button
                          type="primary"
                          icon={<MdRefresh />}
                          loading={loadings[1]}
                          onClick={() => enterLoading(1, item)}
                          className="updateDetailsBtn w-50"
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="logWrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="logsWrap">
                <div className="topSection">
                  <h4 className="title">Inventory Change Log</h4>
                  <ul className="logList">
                    <li>
                      <span className="logText">a few seconds ago.</span>
                    </li>
                  </ul>
                </div>
                <div className="bottomSection">
                  <h4 className="title mb-2">Last Change to Inventory</h4>
                  <ul className="logList">
                    <li>
                      <span className="logText">a few seconds ago.</span>
                    </li>
                    <li>
                      <span className="logText">10min ago.</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;