import React, { useState, useEffect } from 'react';
import { Table, Input } from "antd";
import { useGetAppointmentMutation } from '../../redux/slices/patientSlice';
import moment from 'moment';

const Appointment = () => {
  const [getAppointment, { isLoading }] = useGetAppointmentMutation();
  const [appointmentData, setappointmentData] = useState([]);
  const [filteredAppointmentData, setFilteredAppointmentData] = useState([]);
  useEffect(() => {
    getAppointment()
      .then((res) => {
        const formattedData = res.data.map((item) => ({
          key: item.id,
          appointment_id: item.appointment_id,
          service_category:item.service_category ,
          booked: item.online_versus_inhouse,
          customer_id: item.customer_id,
          booktime: moment(item.created_at).format('DD/MM/YYYY'),
          lastseen:moment(item.updated_at).format('DD/MM/YYYY')  ,
          status: item.booking_status
        }));
        setappointmentData(formattedData);
        setFilteredAppointmentData(formattedData);
      })
      .catch((err) => {
        console.error("err", err);
      });
  }, [getAppointment]);
  const tableColumns = [
    {
      title: "Appointment Id",
      dataIndex: "appointment_id",
      key: "appointment_id",
    },
    {
      title: "Category",
      dataIndex: "service_category",
      key: "service_category",
    },
    {
      title: "Booking",
      dataIndex: "booked",
      key: "booked",
    },
    {
      title: "Customer Id",
      dataIndex: "customer_id",
      key: "customer_id",
    },
    {
      title: "Booking Time",
      dataIndex: "booktime",
      key: "booktime",
    },
    {
      title: "Last Updated",
      dataIndex: "lastseen",
      key: "lastseen",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  //   For searching the elements in the SearchBar
  const handleAppointmentSearch = (event) => {
    const searchText = event.target.value.toLowerCase();

    const filteredData = appointmentData.filter((item) =>
      item.name.toLowerCase().includes(searchText)
    );
    setFilteredAppointmentData(filteredData);
  };
  return (
    <div className="wrapper">
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="titleBar d-flex justify-content-between">
              <h4>Patients Catalog</h4>
              <Input
                placeholder="Search by patient name"
                onChange={handleAppointmentSearch}
                className="homesearchInput"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="tableWrapper">
              <Table
                className="productTable"
                columns={tableColumns}
                dataSource={filteredAppointmentData}
                pagination={true}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Appointment