import React from "react";
import { Layout, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { MdHomeFilled, MdCategory, MdPerson, MdAutoAwesomeMosaic, MdAddBox  } from "react-icons/md";
import { FaHospitalUser } from "react-icons/fa6";
import { LuCalendarCheck2 } from "react-icons/lu";
import Logo from  "../../assests/images/logo.svg"

const { Sider } = Layout;

const Sidebar = () => {
    let navigate = useNavigate();

    const menuIitems = [
        {
            key: "/",
            icon: <MdHomeFilled />,
            label: <Link to="/">Home</Link>,
        },
        {
            key: "/category",
            icon: <MdCategory />,
            label: <Link to="/category">Categories</Link>,
        },
        {
            key: "/add-product",
            icon: <MdAddBox  />,
            label: <Link to="/add-product">Add Product</Link>,
        },
        {
            key: "/my-account",
            icon: <MdPerson />,
            label: <Link to={"/my-account"}>My Account</Link>,
        },
        {
            key: "/inventory",
            icon: <MdAutoAwesomeMosaic />,
            label: <Link to={"/inventory"}>Inventory</Link>,
        },
        {
            key: "/patients",
            icon: <FaHospitalUser  />,
            label: <Link to={"/patients"}>Patients</Link>,
        },
        {
            key: "/appointments",
            icon: <LuCalendarCheck2  />,
            label: <Link to={"/appointments"}>Appointments</Link>,
        },
    ];

    const handleNavigate = () => {
        navigate("/login");
    }

    return (
        <Sider className="sidebar" width={300} breakpoint="lg" collapsedWidth="0">
            <div className="topSection">
                <div className="logoWrap">
                    <Link to={"/"}>
                        <img className="img-fluid" src={Logo} alt="logo" />
                    </Link>
                </div>
                <div className="menuWrap">
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={["1"]}
                        items={menuIitems}
                    />
                </div>
            </div>
            <div className="bottomSection">
                <div className="signInWrap">
                    <p className="mainText">Sign in to test this template</p>
                    <p className="subText">If you included sample data, sign in as any sample user with the password 123.</p>
                    <button type="button" className="btn sampleBtn" onClick={() => handleNavigate()}>
                        <MdPerson />
                        Sign in as Sample User
                    </button>                        
                </div>
            </div>
        </Sider>
    );
};

export default Sidebar;
