import React, { useState, useEffect } from "react";
import { Table, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { MdAddBox } from "react-icons/md";
import { useGetProductMutation } from "../../redux/slices/productSlice";

const Homepage = () => {
  const navigate = useNavigate();
  const [getProduct, { isLoading }] = useGetProductMutation();
  const [productData, setproductData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // State to manage filtered data
  useEffect(() => {
    getProduct()
      .then((res) => {
        const formattedData = res.data.map((item) => ({
          key: item.id,
          imageUrl: item.image,
          name: item.item_name,
          category: item.category.name,
          price: `$${item.price}`,
          stock: item.quantity,
        }));
        setproductData(formattedData);
        setFilteredData(formattedData);
      })
      .catch((err) => {
        console.error("err", err);
      });
  }, [getProduct]);
  const tableColumns = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (text, record) => (
        <div className="imgBox">
          <img
            src={record.imageUrl}
            alt="Product"
            className="img-fluid"
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "In Stock",
      dataIndex: "stock",
      key: "stock",
    },
  ];

  const handleAddProduct = () => {
    navigate("/add-product");
  };

  //   For searching the elements in the SearchBar
  const handleProductSearch = (event) => {
    const searchText = event.target.value.toLowerCase();

    const filteredData = productData.filter((item) =>
      item.name.toLowerCase().includes(searchText)
    );
    setFilteredData(filteredData);
  };
  const handleRowClick = (record) => {
    navigate("/product-details", {
      state: { productId: record.key },
    });
  };
  return (
    <div className="wrapper">
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="titleBar d-flex justify-content-between">
              <h4>Product Catalog</h4>
              <Input
                placeholder="Search by product name or description."
                onChange={handleProductSearch}
                className="homesearchInput"
              />
              <div className="buttonWrap">
                <button
                  type="button"
                  className="btn backBtn"
                  onClick={() => handleAddProduct()}
                >
                  <MdAddBox /> Add Product
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="tableWrapper">
              <Table
                className="productTable"
                columns={tableColumns}
                dataSource={filteredData}
                pagination={true}
                loading={isLoading}
                onRow={(record) => ({
                  onClick: () => handleRowClick(record),
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
